import { Gateway } from "@lernetz/svelte-model-api";
import type { SearchResult } from "@lernetz/svelte-search";
import type { CollectionMaterial, Progress, Material } from "./models";
import { data } from "../services";
import { authentication } from "@microsoft/teams-js";

export interface SearchParams {
    query: string;
    sortDir: "asc" | "desc";
    offset: number;
    limit: number;
    filters: Record<string, string[]>;
    progress?: boolean;
}

export type MaterialSearchParams = {
    scope?: "my" | "public";
    query: string;
    sortDir: string;
    pageSize: number;
    page: number;
    lang: string;
    filters: Record<string, string[]>;
    progress?: boolean;
};


export type MaterialSearchMetadata = {
    facetDistribution: Record<string, Record<string, number>>;
};

export type MaterialSearchResponse = SearchResult<CollectionMaterial, MaterialSearchMetadata>;

export class FlinkGateway extends Gateway {

    async material( publicId: string ) {
        let headers = await this.prepareHeaders();
        return this.base.jsonApi( `/s/${ publicId }/api/material`, {}, headers );
    }

    async searchCollectionMaterials( collectionPublicId: string, searchParam: SearchParams ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `s/${ collectionPublicId }/api/search`, searchParam, headers )
            .then<MaterialSearchResponse>( this.in );
    }

    async lastVisited() {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/lastVisited/`, { limit: 3, modelName: "App\\Models\\Material" }, headers )
            .then<Progress[]>( this.in );
    }

    async shareProgress( publicId: string ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/shareProgress/`, { publicId }, headers )
            .then<Progress>( this.in );
    }

    async shareProgresssAverage( publicIds: string[] ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/shareProgressesAverage/`, { publicIds }, headers )
            .then<{ average: number; }>( this.in );
    }

    private async prepareHeaders() {
        let token = await this.getAuthToken();
        return { Accept: "application/json", "Content-Type": "application/json", Authorization: "Bearer " + token };
    }

    private async getAuthToken() {
        if( data.env == 'local' ) {
            // TODO get token from backend
            // teams
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSJ9.eyJhdWQiOiJiZTY0ZTM5YS1lYmJmLTRmMzUtYTU5NC02NTg1ZmIyZmY2OTkiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjhjNGVlZjItODYyYS00MjExLWE5OWUtZDQwY2Y2MWY5YmM3L3YyLjAiLCJpYXQiOjE3MTExMDg2MTMsIm5iZiI6MTcxMTEwODYxMywiZXhwIjoxNzExMTE3Nzc0LCJhaW8iOiJBV1FBbS84V0FBQUFCc0ZScTNEdnVVQjhWMWpZS3AwMHpFR25wamR1R0F4QmFXYjJ2Sy8vWThvNklGb1VVNG1Ec3lVZGd0NitCOHpXUjZqdHRmWWVsNS9tMkdKYTNnTDRkeG1aeGZYT1JEa3RZQkZEN2ZSMkNDKzdlaTVBZ0tieCtkT2RTRDNxaHR3OSIsImF6cCI6IjVlM2NlNmMwLTJiMWYtNDI4NS04ZDRiLTc1ZWU3ODc4NzM0NiIsImF6cGFjciI6IjAiLCJlbWFpbCI6ImFkbWluLmJlcnRob2xkQGdlYmFldWRlaHVlbGxlbnBsYW5lci5zd2lzcyIsImZhbWlseV9uYW1lIjoiQmVydGhvbGQiLCJnaXZlbl9uYW1lIjoiQWRtaW4iLCJuYW1lIjoiQWRtaW4gQmVydGhvbGQiLCJvaWQiOiI2MDRkOWU5Ny1lNzAxLTRlYTItODQ1My02YWM5YjQwYjUzMGQiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhZG1pbi5iZXJ0aG9sZEBnZWJhZXVkZWh1ZWxsZW5wbGFuZXIuc3dpc3MiLCJyaCI6IjAuQWE0QTh1N0UtQ3FHRVVLcG50UU05aC1ieDVyalpMNl82elZQcFpSbGhmc3Y5cG11QUJRLiIsInNjcCI6ImFjY2VzcyIsInN1YiI6ImVOekt5M1ppcFFCQTZTNVlIT3Y4ekwyUVdkclFianRpeDFiTUtBVnQ5LXciLCJ0aWQiOiJmOGM0ZWVmMi04NjJhLTQyMTEtYTk5ZS1kNDBjZjYxZjliYzciLCJ1dGkiOiJMSklORFAwMi1rZVlkU0QzQnNsRUFBIiwidmVyIjoiMi4wIn0.DMUldaYEpyvBKxDNKA6dfHY0l1s5NOImEZQloat65xVPQgFrlHMXOKHIs2EXKvnLN7wpuPIKnfjQKBX79iuRULO7U3K2XpjghJjkeuNiio1JBwRC8wmM0XTJCfUU5HR2-rfqIKob8Xy25cuq4sUN3ydvx7RVU8W5vyXrHfNbv3I3xuC2dCvqTrRonaknPFhzfdTvUmov6f7iTTzj_GBwrUQXlvZohOJcgvVRVMRilCPg4yHqCltj4FPFOFb4L0Az4UGbg5h66CbbyQFmN1gIMpxkgqsi_5tKyvK2D3aqJGYEuZXq9hRNM84gMjuUZC12AZdSgiaWMTk-vXV5NiVWlw";
        } else {
            return await authentication.getAuthToken();
        }
    }

}



